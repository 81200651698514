




























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {SalesTaxBankStatement, SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";
import SalesTaxService from "@/services/SalesTaxService";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AdminService from "@/services/AdminService";
import TextFieldModal from "@/components/common/TextFieldModal.vue";
import {SalesTaxBankStatementStatus, SalesTaxReportFormat, SalesTaxWorkflowStatus} from "@/constants/SalesTaxConstants";
import RouteNames from "@/router/RouteNames";
import SublimeEmployeeFilterDTO from "@/dto/sublime/employee/SublimeEmployeeFilterDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

const AppModule = namespace("App");

@Component({
  computed: {
    SalesTaxReportFormat() {
      return SalesTaxReportFormat
    }
  }
})
export default class EmployeeWorkflowPreparationStage extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  workflow!: SalesTaxWorkflow;

  @Prop()
  private reload!: () => void

  employees: Array<SublimeEmployeeDTO> = [];

  uploadMode = false;

  mounted() {
    this.loadEmployees();
  }

  loadEmployees() {
    this.startLoading();
    return AdminService.getEmployees(new SublimeEmployeeFilterDTO()).then(
        response => {
          this.employees = response.data.data;
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    )
  }

  loadWorkflow() {
    this.startLoading();
    SalesTaxService.getWorkflowByKey(this.getKey).then(
        response => {
          this.workflow = response.data;
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    )
  }

  get getKey(): string {
    return this.$route.params.key;
  }

  showRequestAdditionalDocumentsModal() {
    this.$modal.show(
        TextFieldModal,
        {
          onOk: this.requestDocument
        }
    )
  }

  requestDocument(description: string) {
    this.startLoading();
    return SalesTaxService.requestAdditionalStatement(this.workflow.id, description).then(
        response => {
          this.workflow.bankStatements.push(response.data)
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  assignWorkflow() {
    if(!this.workflow.assignee){
      return;
    }
    this.startLoading();
    SalesTaxService.assignEmployeeToWorkflow(this.workflow.id, this.workflow.assignee).then(
        response => {
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  changeStatementStatus(statement: SalesTaxBankStatement) {
    this.startLoading();
    SalesTaxService.changeStatementStatus(this.workflow.id, statement.id, statement.status).then(
        response => {
          this.stopLoading();
          this.reload();
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    );
  }

  documentsAccepted() {
    this.workflow.status = SalesTaxWorkflowStatus.DOCUMENTS_ACCEPTED;
    this.startLoading();
    return SalesTaxService.editWorkflow(this.workflow).then(
        response => {
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    );
  }

  get isReadyForUpload(): boolean {
    const readyStatuses = [SalesTaxBankStatementStatus.DECLINED, SalesTaxBankStatementStatus.PROCESSED]
    return this.workflow.bankStatements.every(st => readyStatuses.includes(st.status));
  }

  get hasRequestedDocuments(): boolean {
    return this.workflow.bankStatements.some(st => st.status == SalesTaxBankStatementStatus.REQUESTED);
  }

  toUploadMode(){
    this.workflow.finalAmount = null;
    this.workflow.preparedReport = null;
    this.uploadMode = true;
  }

  showUploadReportModal() {
    this.$modal.show(
        FileUpload,
        {
          multiple: true,
          onUploaded: (files: FileMetaDTO[]) => this.onReportUploaded(files)
        }
    )
  }

  onReportUploaded(files: FileMetaDTO[]) {
    this.workflow.preparedReport = files[0];
    this.$modal.hideAll();
  }

  saveWorkflow(){
    this.workflow.status = SalesTaxWorkflowStatus.ON_CUSTOMERS_APPROVAL;
    this.startLoading();
    SalesTaxService.editWorkflow(this.workflow).then(
        response => {
          this.stopLoading();
          this.$router.push({name: RouteNames.SALES_TAX_WORKFLOWS, params: {companyId: this.$route.params.companyId}});
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  get wasAddressUpdatedWithin30Days(): boolean {
    if (!this.workflow?.address?.updateDate) {
      return false;
    }
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const updateDate = new Date(this.workflow.address.updateDate);
    return updateDate > thirtyDaysAgo;
  }


}
