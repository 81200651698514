













import {Component, Prop, Vue} from 'vue-property-decorator';
import {SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";
import SalesTaxService from "@/services/SalesTaxService";
import {namespace} from "vuex-class";
import EmployeeWorkflowPreparationStage from "@/components/salestax/workflow/EmployeeWorkflowPreparationStage.vue";
import {SalesTaxWorkflowStatus} from "@/constants/SalesTaxConstants";
import EmployeeWorkflowCompletedStage from "@/components/salestax/workflow/EmployeeWorkflowCompletedStage.vue";
import EmployeeWorkflowRejectedStage from "@/components/salestax/workflow/EmployeeWorkflowRejectedStage.vue";

const AppModule = namespace("App");

@Component({
  components: {EmployeeWorkflowRejectedStage, EmployeeWorkflowCompletedStage, EmployeeWorkflowPreparationStage}
})
export default class EmployeeWorkflow extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  workflow: SalesTaxWorkflow | null = null;

  mounted() {
    this.loadWorkflow();
  }

  loadWorkflow() {
    this.startLoading();
    SalesTaxService.getWorkflow(this.getWorkflowId).then(
        response => {
          this.workflow = response.data;
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    )
  }

  get getWorkflowId(){
    return Number.parseInt(this.$route.params.workflowId, 10);
  }

  get isCreatedMode(): boolean{
    return !!this.workflow && this.workflow.status == SalesTaxWorkflowStatus.CREATED;
  }

  get isEditMode(): boolean{
    const editModeStatuses = [SalesTaxWorkflowStatus.PENDING_FOR_DOCUMENTS, SalesTaxWorkflowStatus.DOCUMENTS_ACCEPTED];
    return !!this.workflow && editModeStatuses.includes(this.workflow?.status);
  }

  get isCompletedMode(): boolean{
    const statuses = [SalesTaxWorkflowStatus.APPROVED_BY_CUSTOMER, SalesTaxWorkflowStatus.ON_CUSTOMERS_APPROVAL];
    return !!this.workflow && statuses.includes(this.workflow?.status);
  }

  get isRejectedMode(): boolean{
    return !!this.workflow && this.workflow?.status == SalesTaxWorkflowStatus.REJECTED_BY_CUSTOMER;
  }

}
