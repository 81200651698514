



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {SalesTaxBankStatement, SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";
import SalesTaxService from "@/services/SalesTaxService";
import {namespace} from "vuex-class";
import AdminService from "@/services/AdminService";
import {SalesTaxBankStatementStatus, SalesTaxWorkflowStatus} from "@/constants/SalesTaxConstants";
import RouteNames from "@/router/RouteNames";
import SublimeEmployeeFilterDTO from "@/dto/sublime/employee/SublimeEmployeeFilterDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

const AppModule = namespace("App");

@Component({})
export default class EmployeeWorkflowRejectedStage extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  workflow!: SalesTaxWorkflow;

  employees: Array<SublimeEmployeeDTO> = [];


  mounted() {
    this.loadEmployees();
  }

  loadEmployees() {
    this.startLoading();
    return AdminService.getEmployees(new SublimeEmployeeFilterDTO()).then(
        response => {
          this.employees = response.data.data;
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    )
  }


  forApproval() {
    this.workflow.status = SalesTaxWorkflowStatus.ON_CUSTOMERS_APPROVAL;
    this.startLoading();
    SalesTaxService.editWorkflow(this.workflow).then(
        response => {
          this.stopLoading();
          this.$router.push({name: RouteNames.SALES_TAX_WORKFLOWS, params: {companyId: this.$route.params.companyId}});
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }


  forProcessing() {
    this.workflow.status = SalesTaxWorkflowStatus.PENDING_FOR_DOCUMENTS;
    this.startLoading();
    SalesTaxService.editWorkflow(this.workflow).then(
        response => {
          this.stopLoading();
          this.$router.push({name: RouteNames.SALES_TAX_WORKFLOWS, params: {companyId: this.$route.params.companyId}});
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }


}
